import { AfterContentInit, Directive, ElementRef, inject, input } from '@angular/core';

@Directive({
	selector: '[slAutoFocus]',
	standalone: true
})
export class AutofocusDirective implements AfterContentInit {
	readonly autoFocusSignal$ = input<boolean>(null, { alias: 'slAutoFocus' });

	readonly #el = inject(ElementRef);

	ngAfterContentInit(): void {
		if (this.autoFocusSignal$() !== false) {
			setTimeout(() => {
				(this.#el.nativeElement as HTMLElement)?.focus();
			}, 500);
		}
	}
}
